// extracted by mini-css-extract-plugin
export var back = "categoryPage-module--back--vNRrN";
export var blogCategoryPage = "categoryPage-module--blog-category-page--drhTC";
export var category = "categoryPage-module--category--GTQpB";
export var categoryPage = "categoryPage-module--category-page--Edqd5";
export var categoryPagePosts = "categoryPage-module--category-page-posts--va++i";
export var date = "categoryPage-module--date--rbgeZ";
export var headerBody = "categoryPage-module--header-body--ZUhn3";
export var pageProgress = "categoryPage-module--page-progress--CftzE";
export var postCard = "categoryPage-module--post-card--xOWZ5";
export var postCardContent = "categoryPage-module--post-card-content--LLHdU";
export var postCardImage = "categoryPage-module--post-card-image--u9gBB";
export var postCardImageWrapper = "categoryPage-module--post-card-image-wrapper--UxDu5";
export var postCardInfo = "categoryPage-module--post-card-info--ymM-F";
export var postCardTitle = "categoryPage-module--post-card-title--L1TOu";
export var primary = "#06004d";
export var progress = "categoryPage-module--progress--M-pfB";
export var secondary = "#4dafd7";
export var wrapper = "categoryPage-module--wrapper--1L8Ak";