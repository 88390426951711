import React, { useState } from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { graphql } from 'gatsby';
import { Layout } from 'components';
import { PostCard } from 'components/BlogLandingPage';
import { capitaliseWord } from 'utils';
import * as styles from './categoryPage.module.scss';
import Section from '../components/Section';

const CategoryPage = ({ location, data: staticData, pageContext }) => {
  // TODO: add seo fields to custom type
  const {
    prismicCategory: { uid, data: pageData },
    allPrismicBlogPost: { edges: categoryPosts },
    // posts,
  } = staticData;

  const {
    content: { html: description },
  } = pageData;
  // TODO: fix so we fetch only blog posts relevant to this category
  // const categoryPosts = filterPosts(posts, uid);

  // Paginate posts
  const postsPerPage = 18;
  const numPages = Math.floor(categoryPosts.length / postsPerPage);
  const [page, setPage] = useState(1);
  const indexZero = 0;
  const topBlogIndex = page * postsPerPage - postsPerPage;
  const bottomBlogIndex = topBlogIndex + postsPerPage;
  const label = `Viewing ${page} of ${numPages} pages`;

  const headerProps = {
    title: {
      text: pageData.name,
    },
    content: {
      html: description,
    },
  };

  const postCardClassnames = {
    type: styles.categoryPage,
    postCard: styles.postCard,
    postCardInfo: styles.postCardInfo,
    postCardTitle: styles.postCardTitle,
    postCardContent: styles.postCardContent,
    postCardImageWrapper: styles.postCardImageWrapper,
    postCardImage: styles.postCardImage,
    date: styles.date,
  };

  const layoutClassnames = {
    headerBody: styles.headerBody,
  };

  return (
    <Layout
      location={location}
      pageData={pageData}
      headerProps={headerProps}
      bodyClassName={styles.blogCategoryPage}
      inheritedClassnames={layoutClassnames}
    >
      <Section className={styles.categoryPagePosts} containerClassName={styles.wrapper}>
        {categoryPosts &&
          categoryPosts.slice(indexZero, bottomBlogIndex).map(post => (
            <PostCard
              post={post}
              // Fix with dynanmic link for blog route
              pathname="blog"
              key={post.node.uid}
              type="category-page"
              contentCharLimit={135}
              headingCharLimit={40}
              inheritedClassnames={postCardClassnames}
              displayDate
              tileLocation="CategoryPage"
            />
          ))}
        {numPages > 1 && (
          <section className={styles.progress}>
            {/* <span className={styles.pageProgress}>{label}</span> */}
            {page < numPages && (
              <button
                type="button"
                className="button highlight view-more"
                onClick={() => {
                  setPage(page + 1);
                  // window.scrollTo(0, 0);
                }}
              >
                View More
              </button>
            )}
            {/* {page > 1 && (
              <button
                type="button"
                className={`${styles.back} button highlight back`}
                onClick={() => {
                  setPage(page - 1);
                  // window.scrollTo(0, 0);
                }}
              >
                Back
              </button>
            )} */}
          </section>
        )}
      </Section>
    </Layout>
  );
};

export default withPrismicPreview(CategoryPage);

export const pageQuery = graphql`
  query CategoryPageBySlug($uid: String!) {
    prismicCategory(uid: { eq: $uid }) {
      uid
      prismicId
      _previewable
      data {
        name
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        content {
          html
        }
      }
    }
    allPrismicBlogPost(
      filter: { data: { categories: { elemMatch: { category: { uid: { eq: $uid } } } } } }
      sort: { fields: data___date_published, order: DESC }
      limit: 9999
    ) {
      edges {
        node {
          uid
          data {
            tile_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 800, placeholder: BLURRED)
                }
                extension
              }
              url
              alt
            }
            categories {
              category {
                uid
              }
            }
            content {
              text
            }
            title {
              text
            }
            date_published
          }
        }
      }
    }
  }
`;
